import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  onValue,
  onDisconnect,
} from "firebase/database";

import EventEmitter from "@onemorestudio/eventemitterjs";
export default class Firebase extends EventEmitter {
  constructor() {
    super();
    // Your web app's Firebase configuration
    //  A MODIFIER AVEC VOTRE CONFIGURATION PERSO
    const firebaseConfig = {
      apiKey: "AIzaSyD9rDcz9z41CHw_Ou7tU-zXu8TlNNZ3Ay8",
      authDomain: "reminix-2ffda.firebaseapp.com",
      databaseURL:
        "https://reminix-2ffda-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "reminix-2ffda",
      storageBucket: "reminix-2ffda.appspot.com",
      messagingSenderId: "384155242584",
      appId: "1:384155242584:web:b2d052a400c212d7f7ba2d",
    };

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
    // const auth = getAuth();
    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     this.uid = user.uid;
    //   }
    // });
    // signInAnonymously(auth)
    //   .then(() => {
    //     console.log("signed in");
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     console.log(errorCode, errorMessage);
    //   });
    this.DATABASE = getDatabase();
    this.resume = false;
  }

  listen(_path, key) {
    // console.log("setup listen");
    const path = ref(this.DATABASE, _path);
    //
    onValue(path, (snapshot) => {
      const val = snapshot.val();
      // console.log("FROM CLASS", val);
      if (!this.resume) {
        this.resume = true;
        return;
      }
      this.emit(key, [val]);
    });
  }

  send(_path, _val) {
    const path = ref(this.DATABASE, _path);
    set(path, _val);
  }
}
