/**
CREATIVE CODING
---
Kill server : CTRL + C
Start server : npm run start
Start secure server : npm run start-https
Final build : npm run build
---
To generate new certificate for https connection with external device run :
#sh
mkcert 0.0.0.0 localhost 127.0.0.1 yourLocalIP ::1
mv 0.0.0.0+4-key.pem certificate.key
mv 0.0.0.0+4.pem certificate.cert
**/

// import Playground from "@onemorestudio/playgroundjs";
import Firebase from "./Firebase";
export default class App {
  constructor() {
    // super();
    // this.draw();
    this.FB = new Firebase();
    this.init();
  }

  init() {
    // const testButton = document.getElementById("test");
    // const writeButton = document.getElementById("write");
    const skipButton = document.getElementById("skip");
    const askButton = document.getElementById("ask");
    // to trigger the page
    const startButton = document.getElementById("start");
    const startContainer = document.querySelector(".start_container");
    const scanButton = document.getElementById("scan");
    const scanContainer = document.querySelector(".scan_container");

    startButton.addEventListener("click", function () {
      startContainer.classList.add("fade-out");
      startContainer.addEventListener("animationend", function () {
        this.style.display = "none";
      });
    });

    this.FB.send("navigation/start", {
      action: false,
    });
    // this.FB.listen("navigation/showButton", "showButton");
    // this.FB.addEventListener("showButton", (data) => {
    //   this.prompt = data.prompt;
    //   document.querySelector(".choices").style.opacity = 1;
    // });

    this.FB.send("navigation/scan", {
      scanning: false,
    });

    // __________________________ ***************** __________________________
    // __________________________ ***************** __________________________
    // __________________________ ***************** __________________________
    // __________________________ ***************** __________________________
    this.FB.listen("navigation/showButton", "showButton");
    this.FB.addEventListener("showButton", (data) => {
      // console.log(data);
      this.prompt = data.prompt;
      document.querySelector(".choices").style.opacity = 1;
    });
    // __________________________ FAIRE FADE OUT SUR LES CHOICES
    this.FB.listen("navigation/hideButton", "hideButton");
    this.FB.addEventListener("hideButton", () => {
      document.querySelector(".choices").style.opacity = 0;
    });

    // __________________________ EVENT POUR LE CLICK SUR LE SCAN
    scanButton.addEventListener("click", async () => {
      // this.log("User clicked scan button");
      scanContainer.classList.add("fade-out");
      scanContainer.addEventListener("animationend", function () {
        this.style.display = "none";
      });

      try {
        const ndef = new NDEFReader();
        await ndef.scan();
        // this.log("> Scan started");

        ndef.addEventListener("readingerror", () => {
          // this.log("Argh! Cannot read data from the NFC tag. Try another one?");
        });

        ndef.addEventListener("reading", async ({ message, serialNumber }) => {
          // this.log(`> Serial Number: ${serialNumber}`);
          // this.log(`> Records: (${message.records.length})`);
          const decoder = new TextDecoder();
          for (const record of message.records) {
            // this.log(`> Record type:  ${record.recordType}`);
            if (record.recordType === "text") {
              // this.log(`> Record data: ${decoder.decode(record.data)}`);
              // this.log(`CHARGEONS LE JSON N°: ${decoder.decode(record.data)}`);
              this.FB.send("identification/diapositive", {
                time: new Date().getTime(),
                id: decoder.decode(record.data),
              });
              // const monJSON = await this.loadJSON(
              //   `./json/${decoder.decode(record.data)}.json`
              // );
              // this.log("json " + monJSON.message);
              // const img = new Image();
              // img.onload = () => {
              //   img.width = 200;
              //   img.height = 200;
              //   document.body.appendChild(img);
              //   this.log(monJSON.message);
              // };
              // img.src = `./images/${monJSON.url}`;
            }
          }
        });
      } catch (error) {
        // this.log("Argh! " + error);
      }
    });

    // __________________________ ***************** __________________________
    // __________________________ ***************** __________________________
    // __________________________ EVENT POUR l'ECRITURE DU TAG NFC
    // writeButton.addEventListener("click", async () => {
    //   this.log("User clicked write button");
    //   try {
    //     const ndef = new NDEFReader();
    //     await ndef.write("august69_16");
    //     this.log("> Message written");
    //   } catch (error) {
    //     this.log("Argh! " + error);
    //   }
    // });

    // makeReadOnlyButton.addEventListener("click", async () => {
    //   this.log("User clicked make read-only button");

    //   try {
    //     const ndef = new NDEFReader();
    //     await ndef.makeReadOnly();
    //     this.log("> NFC tag has been made permanently read-only");
    //   } catch (error) {
    //     this.log("Argh! " + error);
    //   }
    // });

    // testButton.addEventListener("click", (e) => {
    //   // envoyer à FIREBASE UN test
    //   this.FB.send("test", { message: "august69_16" });
    // });
    // __________________________ ***************** __________________________
    // __________________________ ***************** __________________________
    // __________________________ EVENT POUR LE CLICK SUR LE SKIP et le ASK
    skipButton.addEventListener("click", () => {
      this.FB.send("navigation/choice", {
        choice: "continue",
        prompt: null,
        date: new Date().getTime(),
      });
    });

    askButton.addEventListener("click", () => {
      this.FB.send("navigation/choice", {
        choice: "call",
        prompt: this.prompt,
        date: new Date().getTime(),
      });
      document.querySelector(".choices").style.opacity = 0;
    });

    startButton.addEventListener("click", () => {
      this.FB.send("navigation/start", {
        message: "Start button clicked",
        date: new Date().getTime(),
        action: true,
      });
    });
  }

  // log(message) {
  //   const log = document.getElementById("log");
  //   log.innerHTML += message + "\n";
  // }

  loadJSON(url) {
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }
}
